import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { SignUpForm } from "./components/SignUpForm";
import { SignInForm } from "./components/SignInForm";
import { Wallet } from "./components/Wallet";
import { WalletContextProvider } from "./contexts/Wallet";
import { SessionContextProvider } from "./contexts/Session";
import { Cart } from "./components/Cart";
import { Nav } from "./components/Nav";
import { WalletNav } from "./components/WalletNav";
import { CollectionCountdown } from "./components/CollectionCountdown";
import { NFTCountdown } from "./components/NFTCountdown";
import { ClientSignup } from "./components/ClientSignup";
import { ClientPay } from "./components/ClientPay";
import { UserSettings } from "./components/UserSettings";
import { Sequence } from "./components/Sequence";
import { SearchBar } from './components/SearchBar';

const COMPONENT_MAP = {
  "sign-up-form": SignUpForm,
  "sign-in-form": SignInForm,
  my_nfts_row: Wallet,
  "purchase-canvas-body": Cart,
  "user-nav": Nav,
  "wallet-nav": WalletNav,
  "collection-countdown": CollectionCountdown,
  "nft-countdown": NFTCountdown,
  "client-signup": ClientSignup,
  "client-pay": ClientPay,
  "user-settings": UserSettings,
  sequence: Sequence,
  searchbar: SearchBar,
};

const renderEl = (el, id) => {
  // Get element "data-*" attributes and pass them along to the react component as props
  const attributes = Array.from(el.attributes).reduce((ret, { name, value }) => {
    if (name.substr(0, 5) === "data-") {
      const key = name.substr(5).replace(/-([a-z])/g, (g) => {
        return g[1].toUpperCase();
      });
      ret[key] = value;
    }
    return ret;
  }, {});

  const root = ReactDOM.createRoot(el);
  const Component = COMPONENT_MAP[id];
  root.render(
    <WalletContextProvider>
      <SessionContextProvider>
        <Component {...attributes} />
      </SessionContextProvider>
    </WalletContextProvider>
  );
};

Object.keys(COMPONENT_MAP).forEach((id) => {
  const el = document.getElementById(id);
  if (el) {
    renderEl(el, id);
  }

  const els = document.querySelectorAll(`[data-react-component="${id}"]`);
  els.forEach((el) => {
    renderEl(el, id);
  });
});
