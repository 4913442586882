import React, { useState, useEffect } from "react";
import { parseTokens } from "../utils";

export const Sequence = (props) => {
  const tokens = parseTokens(props.tokens); // TODO Memoize this so it doesn't recalculate each render.

  const [selectedSeq, setSelectedSeq] = useState(
    !isNaN(parseFloat(props.selectedToken)) ? parseFloat(props.selectedToken) : tokens.find((t) => !t.isSold)?.sequence
  );

  useEffect(() => {
    // This is accessed by the Cart.js component.
    // TODO is there a better way to handle changes?
    if (selectedSeq) {
      // update url
      window.history.pushState({}, null, window.location.href.split("/").slice(0, 5).join("/") + "/" + selectedSeq);
      window.selectedTokenID = tokens[selectedSeq - 1].id;

      // TODO Toggle NFT button
      const btnDom = document.getElementById("purchase-button");
      if (tokens[selectedSeq - 1].isSold) {
        btnDom.disabled = true;
        btnDom.innerText = "NFT has been Sold";
      } else {
        btnDom.disabled = false;
        btnDom.innerHTML = `
        <i class="fas fa-shopping-cart text-white"></i>
          &nbsp;Purchase NFT &raquo; ${props.nftPrice}
        `;
      }
    }
  }, [tokens, selectedSeq]);

  if (!selectedSeq) {
    return <>Sold Out</>;
  }

  return (
    <>
      <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        #{selectedSeq} of {tokens.length}
      </button>
      <ul className="dropdown-menu">
        {tokens.map((token) => (
          <li key={token.id}>
            <button className={"dropdown-item " + (token.isSold ? "disabled" : "")} onClick={() => setSelectedSeq(token.sequence)}>
              #{token.sequence} of {tokens.length}
              {token.isSold ? " (sold)" : ""}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};
