import React, { useEffect, useState } from "react";
import { API } from "../services/API";

export const SessionContext = React.createContext();

export class Needs2FAError extends Error {}

export const SessionContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [subscriptions, setSubscriptions] = useState([]);
  const [isSignedIn, setIsSignedIn] = useState(); // null = uninitialized, false = not connected, true = connected

  const signIn = (login, password, token2fa = null) => {
    return API.login(login, password, token2fa).then((user) => {
      if (!user.uid) {
        throw new Needs2FAError("Needs two-factor authentication token.");
      }

      // we don't need to set the user or session because we're redirecting and it will have to load the data anew.
      // TODO I assume we always want to redirect?
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.get("redirect")) {
        window.location.href = urlParams.get("redirect");
      } else {
        window.location.href = "/wallet";
      }
    });
  };

  const signOut = () => {
    window.location.href = "/web/session/logout?redirect=/sign-in.html";
  };

  useEffect(() => {
    // Check if we're already logged in
    API.getSession()
      .then((user) => {
        setIsSignedIn(true);
        setUser(user);
      })
      .then(() => {
        API.getSubscriptions().then((results) => {
          setSubscriptions(results.subscriptions || []);
        });
      })
      .catch(() => {
        setIsSignedIn(false);
      });
  }, []);

  return <SessionContext.Provider value={{ isSignedIn, user, subscriptions, setUser, signIn, signOut }}>{children}</SessionContext.Provider>;
};
