import React, { useState } from "react";

import { STRIPE_PROMISE } from "../services/Stripe";
import { Spinner } from "./Spinner";
import { Elements, useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";

export const StripeInner = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href,
      },
    });

    props.onError(error.message || "Unknown error has occured.");
    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="card-body">
        <PaymentElement id="payment-element" />
      </div>
      <button disabled={isLoading || !stripe || !elements} id="payment_footer" className={props.className}>
        <strong className="card-price">Pay Now {props.pricing && <span className="pl-2">({props.pricing})</span>} {isLoading && <Spinner className="ms-3" size={20} />}</strong>
      </button>
    </form>
  );
};

export const Stripe = (props) => {
  if (!props.clientSecret) {
    return null;
  }

  return (
    <Elements
      options={{
        clientSecret: props.clientSecret,
      }}
      stripe={STRIPE_PROMISE}
    >
      <StripeInner onError={props.onError} clientSecret={props.clientSecret} pricing={props.pricing} className={props.className} />
    </Elements>
  );
};
