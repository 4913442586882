const axios = window.axios || undefined;

class APICLS {
  request(url, params, controller) {
    if (!axios) {
      throw new Error("Axios not available");
    }

    const options = {
      jsonrpc: "2.0",
      method: "call",
      params,
    };

    if (controller) {
      options.signal = controller.signal;
    }

    return axios.post(url, options).then(({ data }) => {
      if (data.error) {
        const err = new Error(data.error.message);
        err.data = data.error.data;
        throw err;
      }

      return data.result;
    });
  }

  login(login, password, token2fa) {
    return this.request("/web/session/authenticate", {
      login,
      password,
      totp_token: token2fa,
    });
  }

  signup(name, email, password, can_send_promo_email = false, company_name = undefined, code) {
    return this.request("/gs/user/new", {
      name,
      email,
      password,
      can_send_promo_email,
      company_name,
      code,
    });
  }

  getSession() {
    return this.request("/web/session/get_session_info");
  }

  stripePurchase(nftTokenID) {
    return this.request("/stripe/crypto_onramp/create", {
      nft_token_id: nftTokenID,
    });
  }

  stripePurchaseStatus(nftTokenID) {
    return this.request("/stripe/crypto_onramp/status", {
      nft_token_id: nftTokenID,
    });
  }

  getSubscriptions() {
    return this.request("/stripe/subscriptions");
  }

  subscribeEmail(email, type, id) {
    return this.request("/gs/email", {
      email,
      [`${type}_id`]: id,
    });
  }

  disableTOTP() {
    return this.request("/gs/user/disable_totp", {});
  }

  generateTOTPToken() {
    return this.request("/gs/user/generate_totp_token", {});
  }

  verifyTOTPToken(id, code) {
    return this.request("/gs/user/verify_totp_token", { id, code });
  }

  getProducts() {
    return this.request("/stripe/products");
  }

  createSubscription(price_id) {
    return this.request("/stripe/subscription/create", { price_id });
  }

  getAvaxSettings() {
    return this.request("/avax/contract");
  }

  verifyEmail(email) {
    return this.request("/gs/signup", { email });
  }

  clientSignUp(data) {
    return this.request("/gs/signup/final", data);
  }

  search(search) {
    const controller = new AbortController();
    return [this.request("/gs/search", { query: search }, controller), controller];
  }
}

export const API = new APICLS();
