import React from "react";

export const Message = (props) => {
  let bg = "bg-success";
  let ico = "fa-circle-check";
  let text = "text-light";

  if (props.variant === "error") {
    bg = "bg-danger";
    ico = "fa-circle-xmark";
  } else if (props.variant === "warning") {
    bg = 'bg-warning';
    ico = 'fa-exclamation-triangle';
    text = "text-dark";
  } else if (props.variant === "info") {
    bg = 'bg-info';
    ico = 'fa-watch';
    text = "text-dark";
  }

  return (
    <div id="status" className={"container py-2 border-bottom " + text + " " + bg + " " + props.className}>
      <div className="row justify-content-center">
        <div className="text-left py-0 my-0">
          <div className="col text-left">
            <i className={"fa-solid pt-2 " + ico}></i>
            &nbsp; {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};
