export const parseTokens = (tokens) => {
  return tokens
    .split(";")
    .map((t) => {
      const [id, sequence, isSold] = t.split("|");
      return {
        id: parseInt(id, 10),
        sequence: parseInt(sequence, 10),
        isSold: isSold === "1",
      };
    })
    .sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
};

export const formatCurrency = (number) => {
  return parseFloat(number || 0).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
