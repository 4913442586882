import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../contexts/Session";
import { API } from "../services/API";
import { Stripe } from "./Stripe";
import { Message } from "./Message";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let queryPriceId = urlParams.get("priceId");
if (queryPriceId === "null") {
  queryPriceId = null;
}

export const ClientPay = () => {
  const { isSignedIn, subscriptions } = useContext(SessionContext);
  const [products, setProducts] = useState();
  const [priceId, setPriceId] = useState(queryPriceId);
  const [clientSecret, setClientSecret] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [message, setMessage] = useState();
  const [messageVariant, setMessageVariant] = useState();
  const [pricing, setPricing] = useState();

  useEffect(() => {
    if (subscriptions.length > 0) {
      window.location.href = "/web";
    }
  }, [subscriptions]);

  useEffect(() => {
    if (isSignedIn === false) {
      window.location.href = "/gs_client/signup.html?priceId=" + priceId;
    }
  }, [isSignedIn, priceId]);

  useEffect(() => {
    API.getProducts().then(({ products }) => setProducts(products));
  }, []);

  useEffect(() => {
    if (isSignedIn && priceId && products) {
      const plan = products.find((p) => p.prices.find((p) => p.id === priceId));
      if (plan) {
        const pricing = plan.prices.find((p) => p.id === priceId);
        setPricing(`${formatter.format(pricing.unit_amount / 100)}/${pricing.recurring.interval}`);
        setSelectedPlan(plan);
        API.createSubscription(priceId).then(({ client_secret }) => setClientSecret(client_secret));
      } else {
        setPriceId();
      }
    }
  }, [isSignedIn, priceId, products]);

  if (!products) {
    return;
  }

  return (
    <div className="container my-1">
      <div className="image"></div>
      <div className="row justify-content-center align-items-center vh-100">
        <div className="col-lg-6 col-lg-5">
          <div className="login-group">
            <div className="logo-box">
              <a href="https://www.grandstation.com">
                <img className="login-logo" src="/gs_client/static/images/gs-logo-dark.svg" alt="Logo" />
              </a>
            </div>

            {!priceId && (
              <>
                <div className="login-box">
                  <h5>Step 2: Select Plan</h5>
                </div>
                {products.map((product) => (
                  <div className="px-2 mb-4 product-box" key={product.id}>
                    <h3>{product.name}</h3>

                    <div
                      className="border border-primary"
                      onClick={() => setPriceId(product.prices.find((p) => p.recurring.interval === "month").id)}
                    >
                      <h2>Monthly - {formatter.format(product.prices.find((p) => p.recurring.interval === "month").unit_amount / 100)}</h2>
                      {formatter.format(product.prices.find((p) => p.recurring.interval === "month").unit_amount / 100)} USD. Auto-billed
                      monthly.
                    </div>

                    <div
                      className="border border-primary"
                      onClick={() => setPriceId(product.prices.find((p) => p.recurring.interval === "year").id)}
                    >
                      <div className="incentive">One month free!</div>
                      <h2>Yearly - {formatter.format(product.prices.find((p) => p.recurring.interval === "year").unit_amount / 100)}</h2>
                      {formatter.format(product.prices.find((p) => p.recurring.interval === "year").unit_amount / 100 / 12)} USD.
                      Auto-billed yearly.
                    </div>
                  </div>
                ))}
              </>
            )}

            {!!priceId && (
              <>
                <div className="login-box">
                  {selectedPlan && (
                    <h5>
                      {selectedPlan.name} Plan{" "}
                      <button type="button" onClick={() => setPriceId()} className="btn btn-secondary px-1 py-0 ml-1">
                        Change
                      </button>
                    </h5>
                  )}
                  <h5>Step 2: Payment</h5>
                </div>

                {message && (
                  <Message className="mb-3" variant={messageVariant}>
                    {message}
                  </Message>
                )}

                <Stripe
                  className="btn btn-primary w-100 text-white"
                  clientSecret={clientSecret}
                  pricing={pricing}
                  onError={(msg) => {
                    setMessage(
                      <>
                        {msg} If you continue to have trouble, please{" "}
                        <a href="https://www.grandstation.com/support/" className="text-light text-decoration-underline">
                          <u>contact us</u>
                        </a>
                        .
                      </>
                    );
                    setMessageVariant("error");
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
