import React, { useEffect, useState } from "react";
import { API } from '../services/API';

export const CollectionCountdown = (props) => {
  const [countdown, setCountdown] = useState();
  const [done, setDone] = useState(false);
  const [submitted, setSubmittedDone] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    let countdownTime = new Date(props.availableAt.replace(' ', 'T') + "Z").getTime();
    const doCalc = () => {
      const now = new Date().getTime();
      const distance = countdownTime - now;

      if (distance < 0) {
        setDone(true);
        clearImmediate(interval);
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setCountdown(days + "d " + hours + "h " + minutes + "m " + seconds + "s");
      }
    };
    doCalc();

    const interval = setInterval(doCalc, 1000);
    return () => clearInterval(interval);
  }, [props.availableAt, setCountdown]);

  const submit = (e) => {
    e.preventDefault();
    API.subscribeEmail(email, 'collection', parseInt(props.collectionId, 10))
    setSubmittedDone(true);
  };

  if (done) {
    return (
      <a href={"collection-single.html/" + props.collectionId}>
        <button type="button" className="btn btn-outline-secondary btn-lg px-4">
          Check Out The Collection
        </button>
      </a>
    );
  }

  return (
    <>
      <div id="countdown" style={{ textAlign: "left" }}>
        {countdown}
      </div>
      {!submitted ? (
        <form onSubmit={submit}>
          <div className="input-group mb-3">
            <input
              type="email"
              required="true"
              className="form-control"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn btn-outline-secondary" type="submit">
                Get Notified
              </button>
            </div>
          </div>
        </form>
      ) : (
        <p className="lead text-success">Thank you for signing up to get notified!</p>
      )}
    </>
  );
};
