import React, { useState, useEffect } from "react";
import { API } from "../services/API";

export const SearchBar = (props) => {
  const [search, setSearch] = useState("");
  const [items, setItems] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (search === "") {
      setItems(undefined);
      return;
    }

    const [response, controller] = API.search(search);
    response.then((items) => setItems(items));
    return () => controller.abort();
  }, [search]);

  return (
    <div className="position-relative">
      <input
        type="search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="form-control form-control-dark"
        aria-label="Search"
        placeholder={props.placeholder}
        onFocus={() => setShowModal(true)}
        onBlur={() => setShowModal(false)}
      />
      {showModal && (
        <div className="mt-1 w-100 position-absolute search-modal bg-main-color text-light">
          {items && items.length ? (
            items.map((item, i) => (
              <div className="item" key={i} onMouseDown={() => window.location.href = item.url}>
                <img src={item.image_url} alt='' />
                <div>
                  <strong>{item.name}</strong>
                  <br />
                  {item.description}
                </div>
              </div>
            ))
          ) : (
            <div className="my-3 mx-3">
              <em>No items found.</em>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
