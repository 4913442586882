import React, { useCallback, useContext, useEffect, useState } from "react";
import { SessionContext } from "../contexts/Session";
import { WalletContext } from "../contexts/Wallet";
import { Message } from "./Message";
import { Spinner } from "./Spinner";
import { API } from "../services/API";
import { Stripe } from "./Stripe";
import { parseTokens, formatCurrency } from "../utils";

const convertSecsToText = (secs) => {
  let text = Math.floor(secs / 60) + ":";
  text += String(secs % 60).padStart(2, "0");
  return text;
};

const PLEASE_NOTE = <>
  Please note: we use Link by Stripe to process NFT purchases with a credit or debit card. For information on the checkout process and common questions please <a className="text-light text-decoration-underline" href="https://www.grandstation.com/purchase-process/" target="_blank">click here</a>.
</>

export const Cart = () => {
  const { buy: buyMe, isConnected, connect: connectMe } = useContext(WalletContext);
  const { isSignedIn } = useContext(SessionContext);

  const [nft, setNFT] = useState({});
  const [message, setMessage] = useState();
  const [messageVariant, setMessageVariant] = useState();
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [clientSecret, setClientSecret] = useState();
  const [selectedTokenID, setSelectedTokenID] = useState(window.selectedTokenID);

  useEffect(() => {
    if (isPurchasing) {
      let interval = setInterval(() => {
        API.stripePurchaseStatus(selectedTokenID).then(({ is_sold }) => {
          console.log(is_sold);
          if (is_sold) {
            setIsPurchasing(false);
            setMessage(
              <>
                Purchase successful! To view NFTs in your wallet, click{" "}
                <a href="/wallet" className="text-light text-decoration-underline">
                  here
                </a>
                .
              </>
            );
            setMessageVariant("success");
          }

        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isPurchasing]);

  const connect = useCallback(() => {
    connectMe()
      .then(() => {
        setMessage(PLEASE_NOTE);
        setMessageVariant("info");
      })
      .catch(() => {
        setMessage(
          "Unable to connect to your wallet. Make sure you have the appropriate plug-in installed and you follow all of the prompts."
        );
        setMessageVariant("error");
      });
  }, [connectMe, setMessage, setMessageVariant]);

  useEffect(() => {
    if (isSignedIn === undefined || Object.keys(nft).length === 0 || !!message) return;

    if (!isSignedIn && nft.currencySymbol !== "AVAX") {
      setMessage("You will be prompted to create an account or sign in to complete your purchase.");
      setMessageVariant("warning");
      return;
    }

    if (!isConnected && nft.currencySymbol === "AVAX") {
      setMessage(
        <>
          You will need to{" "}
          <button type="button" onClick={connect} className="text-black btn btn-link p-0 align-bottom">
            connect
          </button>{" "}
          your wallet before you can complete your purchase.
        </>
      );
      setMessageVariant("warning");
      return;
    }

    setMessage(PLEASE_NOTE);
    setMessageVariant("info");
  }, [nft, isSignedIn, message, connect, isConnected]);

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      if (window.selectedTokenID && window.selectedTokenID !== selectedTokenID) {
        setSelectedTokenID(window.selectedTokenID);
      }
    }, 200);
    return () => clearInterval(interval);
  }, [selectedTokenID]);

  useEffect(() => {
    const $el = document.getElementById("purchase-canvas-body");
    const tokens = parseTokens($el.getAttribute("data-tokens"));

    if (!selectedTokenID) {
      setSelectedTokenID(tokens[0].id);
    }

    setNFT({
      imageUrl: $el.getAttribute("data-image-url"),
      name: $el.getAttribute("data-name"),
      id: $el.getAttribute("data-id"),
      currencySymbol: $el.getAttribute("data-currency-symbol"),
      fixedPrice: $el.getAttribute("data-fixed-price"),
      transactionFee: $el.getAttribute("data-transaction-fee"),
      totalPrice: $el.getAttribute("data-total-price"),
      fixedPriceUSD: $el.getAttribute("data-fixed-price-usd"),
      transactionFeeUSD: $el.getAttribute("data-transaction-fee-usd"),
      totalPriceUSD: $el.getAttribute("data-total-price-usd"),
      tokens,
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const status = urlParams.get("redirect_status") || urlParams.get("status");
    if (status === "success" || status === "succeeded") {
      setMessage(
        <>
          Purchase successful! To view NFTs in your wallet, click{" "}
          <a href="/wallet" className="text-light text-decoration-underline">
            here
          </a>
          .
        </>
      );
      setMessageVariant("success");
    } else if (status === "cancel" || status === "requires_payment_method" || status === "requires_source") {
      setMessage(
        <>
          Unfortunately we encountered an error while processing this transaction. The error has been logged for us to review. If you
          continue to have trouble, please{" "}
          <a href="https://www.grandstation.com/support/" className="text-light text-decoration-underline">
            <u>contact us</u>
          </a>
          .
        </>
      );
      setMessageVariant("error");
    } else if (status === "processing") {
      setMessage(<>Your payment is processing.</>);
      setMessageVariant("warning");
    }
  }, []);

  const buy = () => {
    if (isPurchasing) return;
    setIsPurchasing(true);
    setMessage(PLEASE_NOTE);
    setMessageVariant("info");

    if (nft.currencySymbol === "AVAX") {
      buyMe(nft.fixedPrice, nft.tokenId)
        .then(() => {
          setMessage(
            <>
              Purchase successful! To view NFTs in your wallet, click{" "}
              <a href="/wallet" className="text-light text-decoration-underline">
                here
              </a>
              .
            </>
          );
          setMessageVariant("success");
        })
        .catch((e) => {
          setMessage(
            <>
              Unfortunately we encountered an error while processing this transaction. The error has been logged for us to review. If you
              continue to have trouble, please{" "}
              <a href="https://www.grandstation.com/support/" className="text-light text-decoration-underline">
                <u>contact us</u>
              </a>
              .
            </>
          );
          setMessageVariant("error");
          setIsPurchasing(false);
        });
    } else if (!isSignedIn) {
      // If not logged in, send to sign in.
      const [url, ...extra] = window.location.href.split("?");
      window.location.href = "/sign-in.html?redirect=" + encodeURIComponent(url + "?status=open");
    } else {
      API.stripePurchase(selectedTokenID)
        .then(({ client_secret }) => {
          setClientSecret(client_secret);
          window.StripeOnramp(window.STRIPE_KEY).createSession({ clientSecret: client_secret }).mount("#onramp-element");

        })
        .catch((e) => {
          console.error(e);
          setMessage(
            <>
              Unfortunately we encountered an error while processing this transaction. The error has been logged for us to review. If you
              continue to have trouble, please{" "}
              <a href="https://www.grandstation.com/support/" className="text-light text-decoration-underline">
                <u>contact us</u>
              </a>
              .
            </>
          );
          setMessageVariant("error");
          setIsPurchasing(false);
        });
    }
  };

  return (
    <>
      <div className="container py-3 border-bottom">
        <div className="row justify-content-center">
          <div className="col text-right">
            <img src={nft.imageUrl} alt="NFT" className="img-cart-thumbnail border border-4" />
          </div>
          <div className="col text-left">
            <p className="text-left pt-2 pb-1 mb-0">{nft.name}</p>
            <p className="lead">{nft.fixedPrice} {nft.currencySymbol}</p>
          </div>
        </div>
      </div>

      <div className="container py-2 border-bottom ">
        <div className="card card-price">
          <div className="card-body">
            <h5 className="card-title py-2 pl-4">
              <i className="fas fa-shopping-bag text-dark"></i>&nbsp;&nbsp;Order Details
            </h5>
            <ul className="list-group list-group-minimal text-muted">
              {nft.tokens && nft.tokens.length > 1 && (
                <li className="list-group-item">
                  Sequence: #{nft.tokens.find((t) => t.id === selectedTokenID).sequence} of {nft.tokens.length}
                </li>
              )}

              <li className="list-group-item">
                NFT Cost: {nft.fixedPrice} {nft.currencySymbol}<br />
                <small>{formatCurrency(nft.fixedPriceUSD)} estimated</small>
              </li>

              {nft.transactionFee && (
                <li className="list-group-item">
                  Transaction Fee: {nft.transactionFee} {nft.currencySymbol}<br />
                  <small>{formatCurrency(nft.transactionFeeUSD)} estimated</small>
                  {/*
                    <a href="#">
                      <i
                        className="far fa-info-circle text-danger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Click for fee details"
                      ></i>
                    </a>
                */}
                </li>
              )}
            </ul>
          </div>

          {message && (
            <Message className="mb-3" variant={messageVariant}>
              {message}
            </Message>
          )}

          {messageVariant === 'success' ? (
            <></>
          ) : (!clientSecret && (
            <button id="payment_footer" className="card-footer cursor-pointer border-0 text-left" onClick={buy}>
              <strong className="card-price">
                Total: {nft.totalPrice} {nft.currencySymbol}{" "}
                {isPurchasing && <Spinner className="ms-3" size={20} />}<br />
                <small>{formatCurrency(nft.totalPriceUSD)} estimated</small>
              </strong>
            </button>
          ))}

          <div id="onramp-element" ></div>
        </div>
      </div>
    </>
  );
};
