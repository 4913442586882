import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../contexts/Session";
import { API } from "../services/API";
import { Alert } from "./Alert";
import { Spinner } from "./Spinner";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let queryPriceId = urlParams.get("priceId");
if (queryPriceId === "null") {
  queryPriceId = null;
}

export const ClientSignup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [message, setMessage] = useState();
  const [messageVariant, setMessageVariant] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [acceptSLA, setAcceptSLA] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [plan, setPlan] = useState();
  const [verificationCode, setVerificationCode] = useState();

  const { isSignedIn } = useContext(SessionContext);

  useEffect(() => {
    if (isSignedIn) {
      window.location.href = "/gs_client/pay.html?priceId=" + queryPriceId;
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (queryPriceId) {
      API.getProducts().then(({ products }) => {
        const plan = products.find((p) => p.prices.find((p) => p.id === queryPriceId));
        if (plan) {
          setPlan(plan.name);
        }
      });
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (password !== repeatPassword) {
      setMessage("Your passwords don't match.");
      setMessageVariant("danger");
      return;
    }

    setMessage();
    setMessageVariant();
    setIsSaving(true);

    API.verifyEmail(email)
      .then(() => {
        setMessage("Thanks for signing up!  Please check your email for a six digit code to verify your account.");
        setMessageVariant("warning");
        setShowForm(false);
      })
      .catch((e) => {
        if (e.data.message) {
          setMessage(
            <>
              {e.data.message}. If you continue to have trouble, please{" "}
              <a href="https://www.grandstation.com/support/" className="text-dark text-decoration-underline">
                <u>contact us</u>
              </a>
              .
            </>
          );
        } else {
          setMessage(
            <>
              Unfortunately we encountered an error while creating your account. The error has been logged for us to review. If you continue
              to have trouble, please{" "}
              <a href="https://www.grandstation.com/support/" className="text-dark text-decoration-underline">
                <u>contact us</u>
              </a>
              .
            </>
          );
        }
        setMessageVariant("danger");
      })
      .then(() => setIsSaving(false));

    return false;
  };

  const onFinalSubmit = (event) => {
    event.preventDefault();

    if (!verificationCode) {
      setMessage("You must enter the verification code you received in your email.");
      setMessageVariant("danger");
      return;
    }

    setIsSaving(true);

    API.clientSignUp({
      login: email,
      password,
      confirm_password: repeatPassword,
      code: verificationCode,
    })
      .then(() => {
        window.location.href = "/gs_client/pay.html?priceId=" + queryPriceId;
      })
      .catch((e) => {
        if (e.data.message) {
          setMessage(
            <>
              {e.data.message}. If you continue to have trouble, please{" "}
              <a href="https://www.grandstation.com/support/" className="text-dark text-decoration-underline">
                <u>contact us</u>
              </a>
              .
            </>
          );
        } else {
          setMessage(
            <>
              Unfortunately we encountered an error while creating your account. The error has been logged for us to review. If you continue
              to have trouble, please{" "}
              <a href="https://www.grandstation.com/support/" className="text-dark text-decoration-underline">
                <u>contact us</u>
              </a>
              .
            </>
          );
        }
        setMessageVariant("danger");
      })
      .then(() => setIsSaving(false));
  };

  const goToSignin = () => {
    // const [url, ...extra] = window.location.href.split("?");
    // window.location.href = "/sign-in.html?redirect=" + encodeURIComponent(url) + "&priceId=" + (queryPriceId || "");
    window.location.href = '/web/login';
  };

  return (
    <div className="container my-1">
      <div className="image"></div>
      <div className="row justify-content-center align-items-center vh-100">
        <div className="col-lg-6 col-lg-5">
          <div className="login-group">
            <div className="logo-box">
              <a href="https://www.grandstation.com">
                <img className="login-logo" src="/gs_client/static/images/gs-logo-dark.svg" alt="Logo" />
              </a>
            </div>
            <div className="login-box">
              {plan && <h5>{plan} Plan</h5>}
              <h5>Step 1: Create Account</h5>
            </div>
            <div className="accordion-content" data-content>
              <div className="accordion-content-wrapper">
                {message && (
                  <Alert className="mb-3" variant={messageVariant}>
                    {message}
                  </Alert>
                )}
                {!showForm && (
                  <form onSubmit={onFinalSubmit}>
                    <div className="form-group">
                      <label htmlFor="signup-email">Six Digit Verification Code</label>
                      <input
                        id="signup-verification-code"
                        required
                        className="form-control"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                      />
                    </div>
                    <button type="submit" disabled={isSaving || !verificationCode} className="btn btn-primary btn-block">
                      GO TO CHECKOUT {isSaving && <Spinner className="ml-3" size={20} />}
                    </button>
                  </form>
                )}
                {showForm && (
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <label htmlFor="signup-email">Email address</label>
                      <input
                        id="signup-email"
                        required
                        type="email"
                        className="form-control"
                        placeholder="e.g. name@.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="signup-password">Password</label>
                      <input
                        id="signup-password"
                        required
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="signup-repeat-password">Repeat Password</label>
                      <input
                        id="signup-repeat-password"
                        required
                        type="password"
                        className="form-control"
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-check mb-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={acceptSLA}
                        onChange={() => setAcceptSLA(!acceptSLA)}
                        required
                        value=""
                        id="flexCheckDefault"
                      />
                      <label className="form-check-label" htmlFor="flexCheckDefault">
                        I agree to the{" "}
                        <a
                          href="https://www.grandstation.com/service-level-agreement/"
                          rel="noreferrer"
                          target="_blank"
                          className="text-success"
                        >
                          Service Level Agreement
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.grandstation.com/terms-and-conditions-for-clients/"
                          rel="noreferrer"
                          target="_blank"
                          className="text-success"
                        >
                          Terms and Conditions
                        </a>
                      </label>
                    </div>
                    <button type="submit" disabled={isSaving || !acceptSLA} className="btn btn-primary btn-block">
                      SIGN UP {isSaving && <Spinner className="ml-3" size={20} />}
                    </button>

                    <p className="text-center mt-2 mb-2">Or</p>

                    <button type="button" onClick={goToSignin} className="btn btn-secondary btn-block">
                      SIGN IN
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
