import React, { useContext } from "react";
import { SessionContext } from "../contexts/Session";
import { WalletContext } from "../contexts/Wallet";

export const WalletNav = () => {
  const { signOut } = useContext(SessionContext);
  const { isConnected, address } = useContext(WalletContext);

  if (isConnected) {
    return <span className="text-light px-3">{address}</span>;
  }

  return (
    <button className="nav-link px-3 text-light btn btn-link p-0 align-bottom" onClick={signOut} id="sign_out">
      Sign Out
    </button>
  );
};
