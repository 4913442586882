import React from "react";

export const Alert = (props) => {
  let ico = "fa-circle-check";

  if (props.variant === "danger") {
    ico = "fa-circle-xmark";
  } else if (props.variant === "warning") {
    ico = "fa-triangle-exclamation";
  } else if (props.variant === "info") {
    ico = "fa-watch";
  }

  return (
    <div className={"alert alert-" + (props.variant || 'success') + " fs-5"} role="alert">
      <i className={"fa-solid " + ico + " pr-1"}></i>{props.children}
    </div>
  );
};
