import React, { useState, useEffect, useContext } from "react";
import { WalletContext } from "../contexts/Wallet";
import { Spinner } from "./Spinner";
import { Message } from "./Message";
import { SessionContext, Needs2FAError } from "../contexts/Session";

export const SignInForm = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [needs2FA, setNeeds2FA] = useState(false);
  const [code, setCode] = useState("");
  const [redirect, setRedirect] = useState("");

  const { connect } = useContext(WalletContext);
  const { isSignedIn, signIn } = useContext(SessionContext);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("redirect")) {
      const redirect = "?redirect=" + encodeURIComponent(urlParams.get("redirect"));
      setRedirect(redirect);
    }
  }, []);

  useEffect(() => {
    // If I'm already logged into Web3, then send to wallet.
    if (isSignedIn) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.get("redirect")) {
        window.location.href = urlParams.get("redirect");
      } else {
        window.location.href = "/wallet";
      }
    }
  }, [isSignedIn]);

  const onSubmit = (e) => {
    e.preventDefault();

    setErrorMessage();
    setIsSaving(true);

    signIn(login, password, needs2FA ? code : undefined).catch((err) => {
      setCode("");
      setIsSaving(false);

      if (err instanceof Needs2FAError) {
        setNeeds2FA(true);
      } else {
        setErrorMessage("Unable to log in. Check your credentials and try again.");
      }
    });
  };

  const connectWallet = () => {
    setErrorMessage();

    connect().then(() => {
      window.location.href = '/wallet';
    }).catch(() => {
      setErrorMessage("Unable to connect wallet. Please try again.");
    });
  };

  if (needs2FA) {
    return (
      <form onSubmit={onSubmit}>
        <h1 className="mb-2 text-dark">Two-factor Authentication</h1>

        {!!errorMessage && (
          <Message variant="error" className="mb-4">
            {errorMessage}
          </Message>
        )}

        <div className="form-group mb-4">
          <label htmlFor="code">Authentication Code (6 digits)</label>
          <input id="code" className="form-control border-0" required="" value={code} onChange={(e) => setCode(e.target.value)} />
        </div>

        <div className="d-grid gap-2">
          <button className="btn btn-success" disabled={isSaving} type="submit">
            Verify
            {isSaving && <Spinner className="ms-2" size={15} />}
          </button>
        </div>
        <p className="mt-3 mb-3 text-muted font-14">
          Open the two-factor authentication app on your device to obtain a code and verify your identity
        </p>
      </form>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <h1 className="mb-2 text-dark">
        Sign In or{" "}
        <a href={"/sign-up.html" + redirect} className="text-dark text-decoration-underline">
          Sign Up
        </a>
      </h1>

      {!!errorMessage ? (
        <Message variant="error" className="mb-4">
          {errorMessage}
        </Message>
      ) : (
        <p className="mb-4">Welcome back!</p>
      )}

      <div className="form-group">
        <label htmlFor="login">Email </label>
        <input
          id="login"
          className="form-control border-0"
          placeholder="Email address"
          required=""
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
      </div>
      <div className="form-group mb-4">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          className="form-control border-0"
          placeholder="Password"
          required=""
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="d-grid gap-2">
        <button className="btn btn-success" disabled={isSaving} type="submit">
          Sign In
          {isSaving && <Spinner className="ms-2" size={15} />}
        </button>
      </div>
      <p className="mt-3 mb-3 text-muted font-14">
        Need an account?{" "}
        <a href={"/sign-up.html" + redirect} className="text-success">
          {" "}
          <u>Sign up</u>
        </a>
      </p>
      <p className="mt-3 mb-3 text-muted font-14">
        If you use a connected wallet, please click{" "}
        <button onClick={connectWallet} type="button" className="text-success btn btn-link p-0 align-bottom">
          here
        </button>{" "}
        to view your purchases.
      </p>
    </form>
  );
};
