import React, { useContext, useEffect } from "react";
import { SessionContext } from "../contexts/Session";
import { WalletContext } from "../contexts/Wallet";

export const Wallet = () => {
  const { isConnected, nfts } = useContext(WalletContext);
  const { isSignedIn } = useContext(SessionContext);

  useEffect(() => {
    if (isConnected === false && isSignedIn === false) {
      window.location.href = "/sign-in.html";
    }
  }, [isConnected, isSignedIn]);

  return nfts.map((nft) => (
    <div className="col-md-4 mt-5">
      <div className="card shadow-sm">
        <a href={"/nft/" + nft.id}>
          <img
            src={nft.image}
            className="d-block mx-lg-auto img-fluid rounded shadow"
            alt="NFT Preview"
            width="500"
            height="500"
            loading="lazy"
          />
        </a>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Title: {nft.name}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Token ID: {nft.tokenId}</th>
              </tr>
              <tr>
                <th scope="row">Token Standard ERC-721</th>
              </tr>
              <tr>
                <th scope="row">Blockchain: Avalanche</th>
              </tr>
              <tr>
                <th scope="row">Rarity Rating: Very Common</th>
              </tr>
              <tr>
                <th scope="row">
                  Cost: {nft.price} {nft.currency}
                </th>
              </tr>
            </tbody>
          </table>
          <div className="d-grid gap-2 col-12 btn-block">
            <a href={"/nft/" + nft.id} className="d-grid gap-2">
              <button type="button" className="btn btn-outline-secondary">
                View NFT
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  ));
};
