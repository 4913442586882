import React, { useContext } from "react";
import { SessionContext } from "../contexts/Session";
import { WalletContext } from "../contexts/Wallet";

export const Nav = () => {
  const { isSignedIn, signOut } = useContext(SessionContext);
  const { isConnected } = useContext(WalletContext);

  if (isConnected) {
    return (
      <a href="/wallet" className="side-by-side-nav">
        Wallet
      </a>
    );
  }

  if (isSignedIn) {
    return (
      <>
        <a href="/wallet" className="side-by-side-nav">
          Wallet
        </a>{" "}
        |{" "}
        <button className="side-by-side-nav btn btn-link p-0 align-bottom text-decoration-none" onClick={signOut}>
          Sign Out
        </button>
      </>
    );
  }

  return (
    <>
      <a href="/sign-up.html" className="side-by-side-nav">
        Create Account
      </a>{" "}
      |{" "}
      <a href="/sign-in.html" className="side-by-side-nav">
        Sign In
      </a>
    </>
  );
};
