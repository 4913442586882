import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../contexts/Session";
import { WalletContext } from "../contexts/Wallet";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { API } from "../services/API";
import QRCode from "react-qr-code";
import { Message } from "./Message";

export const UserSettings = () => {
  const { isConnected, nfts } = useContext(WalletContext);
  const { isSignedIn, user, setUser } = useContext(SessionContext);
  const [totp, setTOTP] = useState();
  const [verificationCode, setVerificationCode] = useState("");
  const [message, setMessage] = useState();

  useEffect(() => {
    if (isConnected === false && isSignedIn === false) {
      window.location.href = "/sign-in.html";
    }
  }, [isConnected, isSignedIn]);

  const disableTOTP = () => {
    API.disableTOTP().then(() => {
      setUser({
        ...user,
        totp_enabled: false,
      });
    });
  };

  const generateTOTPToken = () => {
    API.generateTOTPToken().then((totp) => {
      setMessage();
      setTOTP(totp);
      setVerificationCode("");
    });
  };

  const verify = () => {
    setMessage();
    API.verifyTOTPToken(totp.id, verificationCode)
      .then(() => {
        setUser({
          ...user,
          totp_enabled: true,
        });
        setTOTP();
      })
      .catch(() => {
        setMessage("Incorrect code. Please try again.");
      });
  };

  return (
    <div>
      {user?.totp_enabled ? (
        <Button variant="primary" onClick={disableTOTP}>
          Disable Two-factor Authentication
        </Button>
      ) : (
        <Button variant="primary" onClick={generateTOTPToken}>
          Enable Two-factor Authentication
        </Button>
      )}
      <Modal show={!!totp} onHide={() => setTOTP()}>
        <Modal.Header closeButton>
          <Modal.Title>Enable Two-Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Scan the image below with the authenticator app on your phone.</p>
          <div className="text-center">{totp?.url && <QRCode value={totp.url} />}</div>

          <p className="mt-3">
            <strong>Enter the 6-digit code from your app</strong>
          </p>
          <p>After scanning the barcode, the app will display a 6-digit code that you should enter below.</p>

          {!!message && (
            <Message className="mb-3" variant="error">
              {message}
            </Message>
          )}

          <div className="form-group">
            <label>Verification Code</label>
            <input
              maxLength={6}
              className="form-control"
              autoFocus
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setTOTP()}>
            Close
          </Button>
          <Button variant="primary" disabled={verificationCode.length !== 6} onClick={verify}>
            Enable
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
