import React, { useCallback, useEffect, useRef, useState } from "react";
import { API } from "../services/API";
import { ethers } from "ethers";

let web3;
if (window.web3) {
  // Check for web3 plugin
  web3 = new window.Web3(window.web3.currentProvider);
}
const PROVIDER_OPTIONS = { network: "fuji", cacheProvider: true };
const web3Modal = new window.Web3Modal.default(PROVIDER_OPTIONS);

export const WalletContext = React.createContext();

export const WalletContextProvider = ({ children }) => {
  // I might not need these refs....
  const signer = useRef();
  const marketplaceContract = useRef();
  const nftContract = useRef();

  const [address, setAddress] = useState();
  const [isConnected, setIsConnected] = useState(); // null = uninitialized, false = not connected, true = connected
  const [nfts, setNFTs] = useState([]);

  const connect = useCallback(() => {
    if (!web3) {
      return Promise.reject("No Web3 plugin installed.");
    }

    return web3Modal
      .connect()
      .then((connection) => {
        return API.getAvaxSettings().then((response) => {
          return [
            connection,
            response.find(r => r.external_id === 'avax.contract_marketplace'),
            response.find(r => r.external_id === 'avax.contract_nft'),
          ]
        });
      })
      .then(([connection, MARKETPLACE, NFT]) => {
        const provider = new ethers.providers.Web3Provider(connection);
        signer.current = provider.getSigner();
        marketplaceContract.current = new ethers.Contract(MARKETPLACE.address, MARKETPLACE.abi, signer.current);
        nftContract.current = new ethers.Contract(NFT.address, NFT.abi, signer.current);
        return marketplaceContract.current.fetchMyNFTs();
      })
      .then((data) => {
        return Promise.all(
          data
            .filter((i) => i.tokenId > 0)
            .map(async (i) => {
              const tokenURI = await nftContract.current.tokenURI(i.tokenId);
              const meta = await window.axios.get(tokenURI);
              let price = ethers.utils.formatUnits(i.price.toString(), "ether");
              var id = meta.data.id;
              if (id == null) {
                id = 2;
              }
              let item = {
                price,
                currency: i.currency,
                tokenId: i.tokenId.toNumber(),
                seller: i.seller,
                owner: i.owner,
                image: meta.data.image,
                id: id, //meta.data.id,
                name: meta.data.name,
                description: meta.data.description,
                tokenURI,
              };
              return item;
            })
        );
      })
      .then((data) => {
        setNFTs(data);
      });
  }, [setNFTs]);

  const onAccounts = useCallback(
    (accounts) => {
      if (accounts.length > 0) {
        // If we have connected accounts, set the first one on the state
        setAddress(accounts[0]);
        setIsConnected(true);
        // Connect to the wallet
        connect();
      } else {
        setAddress();
        setIsConnected(false);
        signer.current = null;
        marketplaceContract.current = null;
        nftContract.current = null;
      }
    },
    [setAddress, setIsConnected, connect]
  );

  const buy = async (price, tokenId) => {
    if (!web3) {
      throw new Error("No Web3 plugin installed.");
    }

    if (!isConnected) {
      await connect();
    }

    const priceInEther = ethers.utils.parseUnits(price, "ether");
    const transaction = await marketplaceContract.current.createMarketSale(nftContract.current.address, tokenId, { value: priceInEther });
    await transaction.wait();
  };

  useEffect(() => {
    if (web3) {
      // Get all accounts
      web3.eth.getAccounts((err, accounts) => onAccounts(accounts));
      // Watch account change
      window.ethereum.on("accountsChanged", onAccounts);
    }
  }, [onAccounts]);

  return <WalletContext.Provider value={{ isConnected, address, nfts, connect, buy }}>{children}</WalletContext.Provider>;
};
