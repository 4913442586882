import React, { useContext, useEffect, useState } from "react";
import { WalletContext } from "../contexts/Wallet";
import { API } from "../services/API";
import { Spinner } from "./Spinner";
import { Message } from "./Message";
import { SessionContext } from "../contexts/Session";

export const SignUpForm = () => {
  const { isConnected, connect } = useContext(WalletContext);
  const { isSignedIn, signIn } = useContext(SessionContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [receivePromotions, setReceivePromotions] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [termsLink, setTermsLink] = useState("/terms.html");
  const [privacyLink, setPrivacyLink] = useState("/privacy-policy.html");
  const [showVerifyForm, setShowVerifyForm] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const [message, setMessage] = useState();
  const [msgVariant, setMsgVariant] = useState();

  useEffect(() => {
    const $el = document.getElementById("sign-up-form");
    setTermsLink($el.getAttribute("data-terms-link"));
    setPrivacyLink($el.getAttribute("data-privacy-link"));
  }, []);

  useEffect(() => {
    // If I'm already logged into Web3, then send to wallet.
    if (isConnected || isSignedIn) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.get("redirect")) {
        window.location.href = urlParams.get("redirect");
      } else {
        window.location.href = "/wallet";
      }
    }
  }, [isConnected, isSignedIn]);

  const onSubmit = (e) => {
    e.preventDefault();

    setMessage();
    setIsSaving(true);

    API.verifyEmail(email)
      .then(() => {
        setMessage(<>Thanks for signing up! Please check your email for a six digit code to verify your account.</>);
        setMsgVariant("success");
        setShowVerifyForm(true);
      })
      .catch((data) => {
        setMessage("Unable to sign up. The email address may already be in use.");
        setMsgVariant("error");
      })
      .then(() => {
        setIsSaving(false);
      });
  };

  const connectWallet = () => {
    setMessage();

    connect()
      .then(() => {
        window.location.href = "/wallet";
      })
      .catch(() => {
        setMessage("Unable to connect wallet. Please try again.");
        setMsgVariant("error");
      });
  };

  const onFinalSubmit = (e) => {
    e.preventDefault();

    setMessage();
    setIsSaving(true);

    API.signup(name, email, password, receivePromotions, null, verificationCode)
      .then(() => {
        signIn(email, password);
      })
      .catch(() => {
        setMessage("Unable to verify your email. Please try again.");
        setMsgVariant("error");
      })
      .then(() => setIsSaving(false));
  };

  if (showVerifyForm) {
    return (
      <form onSubmit={onFinalSubmit}>
        <h1 className="mb-2 text-dark">
          Sign Up or{" "}
          <a href="/sign-in.html" className="text-dark text-decoration-underline">
            Sign In
          </a>
        </h1>

        {!!message ? (
          <Message variant={msgVariant} className="mb-4">
            {message}
          </Message>
        ) : (
          <p className="mb-4">Let's get your account set up:</p>
        )}

        <div className="form-group">
          <label htmlFor="signup-email">Six Digit Verification Code</label>
          <input
            id="signup-verification-code"
            required
            className="form-control"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </div>
        <button type="submit" disabled={isSaving || !verificationCode} className="btn btn-primary btn-block">
          Verify Account {isSaving && <Spinner className="ml-3" size={20} />}
        </button>
      </form>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <h1 className="mb-2 text-dark">
        Sign Up or{" "}
        <a href="/sign-in.html" className="text-dark text-decoration-underline">
          Sign In
        </a>
      </h1>

      {!!message ? (
        <Message variant={msgVariant} className="mb-4">
          {message}
        </Message>
      ) : (
        <p className="mb-4">Let's get your account set up:</p>
      )}

      <div className="form-group">
        <label htmlFor="name">Name </label>
        <input
          id="name"
          className="form-control border-0"
          placeholder="Full name"
          required=""
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email </label>
        <input
          type="email"
          id="email"
          className="form-control border-0"
          placeholder="Email address"
          required=""
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="form-group mb-4">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          className="form-control border-0"
          placeholder="Password"
          required=""
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" required value="" id="flexCheckDefault" />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          I agree to the{" "}
          <a href={termsLink} rel="noreferrer" target="_blank" className="text-success">
            Terms &amp; Conditions
          </a>{" "}
          and{" "}
          <a href={privacyLink} rel="noreferrer" target="_blank" className="text-success">
            Privacy Policy
          </a>
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={receivePromotions}
          onChange={() => setReceivePromotions(!receivePromotions)}
          id="flexCheckChecked"
        />
        <label className="form-check-label" htmlFor="flexCheckChecked">
          Receive discount and promotional emails
        </label>
      </div>

      <div className="d-grid gap-2">
        <button className="btn btn-success" disabled={isSaving} type="submit">
          Sign Up
          {isSaving && <Spinner className="ms-2" size={15} />}
        </button>
      </div>
      <p className="mt-3 mb-3 text-muted font-14">
        Already have an account?{" "}
        <a href="sign-in.html" className="text-success">
          {" "}
          <u>Sign in</u>
        </a>
      </p>
      <p className="mt-3 mb-3 text-muted font-14">
        If you use or have used a connected wallet, please click{" "}
        <button onClick={connectWallet} type="button" className="text-success btn btn-link p-0 align-bottom">
          here
        </button>{" "}
        to view your purchases.
      </p>
    </form>
  );
};
